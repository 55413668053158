@charset "UTF-8";
@import "variables";

@media screen and (max-width: 1024px) and (orientation: portrait) {

	// // ---------------------------------------------------------------------------

	// // body

	// // ---------------------------------------------------------------------------

	// body {
	// 	font-family: 'Noto Sans JP', sans-serif;
	// 	font-family: "Zen Kaku Gothic New", sans-serif;
	// 	font-size: calc(1.3rem + .25vw);
	// 	font-weight: 400;
	// 	line-height: 1.8em;
	// 	letter-spacing: .05em;
	// 	width: 100%;
	// 	.fix-bg {
	// 		width: 100%;
	// 		height: 100%;
	// 		position: fixed;
	// 		top: 0;
	// 		left: 0;
	// 		background: url(../images/common/content-bg.webp)no-repeat center center;
	// 		background-size: cover;
	// 		z-index: -1;
	// 	}
	// 	&.admission .fix-bg {
	// 		background: url(../images/target/target-admission-main_bg.webp)no-repeat center center;
	// 		background-size: cover;
	// 	}
	// }


	// // ---------------------------------------------------------------------------

	// // header／nav

	// // ---------------------------------------------------------------------------

	.header {
		// 	width: 100%;
		height: 10vh;
		padding: 0 24vw 0 0;

		// 	position: fixed;
		// 	top: 0;
		// 	left: 0;
		// 	display: flex;
		// 	justify-content: space-between;
		// 	align-items: center;
		// 	z-index: 20;
		// 	// background: #FFF;
		// 	transition:all .4s ease;
		.header-logo {
			// 		width: fit-content;
			height: 6vh;
			// 		display: flex;
			// 		align-items: center;
			// 		background: $main-color;
			// 		transition:all .4s ease;
			// 		img {
			// 			height: 100%;
			// 			display: block;
			// 		}
		}

		.multi-lang {
			width: 12vw;

			// display: block;
			.img {
				// width: 100%;
				height: 8vw;
				// transition:all .4s ease;
				// img {
				// 	width: 100%;
				// 	height: 100%;
				// 	object-fit:contain;
				// 	display: block;
				// }
			}

			span {
				// display: block;
				font-size: 1rem;
				line-height: 1.2em;
				// letter-spacing: 0;
				// color: #FFF;
				text-align: center;
				font-weight: 500;
			}
		}

		&::before {
			// 		content:'';
			// 		width: 100%;
			height: 10vh;
			// 		background: $main-color;
			// 		position: absolute;
			// 		top: 0;
			// 		left:0;
			// 		z-index: -1;
			// 		opacity: 0;
			// 		transform:translateY(-4vw);
			// 		pointer-events:none;
			// 		transition:all .2s ease ;
		}

		&.hide-out {
			height: 10vh;
			padding: 0 24vw 0 0;

			// 		// background: rgba(#FFF, .85);
			// 		// background: $main-color;
			.header-logo {
				height: 6vh;
				transform: translateY(-1vh);
			}

			.multi-lang {
				.img {
					height: 8vw;
				}
			}

			// 		.header-menu {
			// 			li {
			// 				a {
			// 					padding: .2vw 1vw ;
			// 					font-size: 1vw;
			// 				}
			// 			}
			// 		}
			// 		&::before {
			// 			content:'';
			// 			opacity: 1;
			// 			transform:translateY(0);
			// 			transition:all .2s ease .2s;
			// 		}
		}

		.top & {
			&.hide-out {
				.header-logo {
					transform: translateY(0);
				}
			}
		}
	}



	// //------------------------------------------------------------------------
	// //side_hamburger_navi
	// //------------------------------------------------------------------------
	@import "menu/_hamburger_navi-res";





	// // ---------------------------------------------------------------------------

	// // content-header／mainvisual

	// // ---------------------------------------------------------------------------

	.content-header {
		.mainvisual {
			// 		width: 100%;
			height: 50vw;
			padding: 34vw 24vw 0;

			h2.mainvisual-category {

				// 			width: 100%;
				// 			img {
				// 				width: 100%;
				// 				display: block;
				// 			}
				&.fix {
					width: 26vw;
					// position: fixed;
					// margin: 0;
					top: 6.2vh;
					left: 6.5vw;
					// z-index: 100;
					// animation: fade_top .7s ease .4s both;
				}
			}

			// 		.education & {
			// 			background: url(../images/mainvisual/mainvisual-education.webp) no-repeat center center;
			// 			background-size: cover;
			// 		}
			.research & {
				background: url(../images/mainvisual/mainvisual-research_res.webp) no-repeat center center;
				background-size: cover;
			}

			.campus & {
				background: url(../images/mainvisual/mainvisual-campus.webp) no-repeat top left;
				background-size: 200%;
			}

			// 		.about & {
			// 			background: url(../images/mainvisual/mainvisual-about.webp) no-repeat center center;
			// 			background-size: cover;
			// 		}
		}

		.category-top & {
			.mainvisual {
				height: 70vw;
				padding: 28vw 24vw 0;

				// position: relative;
				&::after {
					// content:'';
					// width: 100%;
					height: 60vw;
					// position: absolute;
					// bottom:0;
					// left:0;
					// background: #DDD;
					background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(200, 200, 200, 1) 100%);

					// mix-blend-mode:multiply;
					// z-index: 1;
				}
			}
		}
	}


	ul.breadcrumb {
		// 	width: 100%;
		// 	padding: 0;
		// 	display: flex;
		flex-wrap: wrap;

		li {
			padding: .3em .8em;

			// 		position: relative;
			&::before {
				// 			content: '';
				width: 1.5vw;
				height: 1.5vw;
				// 			border-right:1px solid #FFF;
				// 			border-bottom:1px solid #FFF;
				// 			position: absolute;
				top: calc(50% - .5vw);
				// 			right: 0;
				// 			transform: rotate(-45deg);
			}

			a,
			p {
				// 			color: #fff;
				// 			display: block;
				font-size: 1rem;
				line-height: 1.6em;
				// 			font-weight: 500;
			}

			&:first-child {
				padding: .3em .8em .3em 0;
			}

			// 		&:last-child{
			// 			&::before{
			// 				content: unset;
			// 			}
			// 		}
		}

		// 	.content-wrap & {
		// 		padding: 1vw 3vw;
		// 		li {
		// 			a , p {
		// 				color: #000;
		// 			}
		// 			&::before{
		// 				border-right:1px solid #000;
		// 				border-bottom:1px solid #000;
		// 			}
		// 		}
		// 	}
	}

	h2.content-title {
		font-size: 2.4rem;
		line-height: 1.5em;
		// 	font-weight: 700;
		// 	padding: .6em 0 .8em;
		// 	text-align: center;
	}






	// // ---------------------------------------------------------------------------

	// // 第二階層 構造設定／pickup_list category-index

	// // ---------------------------------------------------------------------------

	.content-category-introduction {
		width: 80%;
		// margin: 0 auto;
		padding: 1vh 4vw;
		font-size: 1.4rem;
		letter-spacing: .02em;
		// line-height: 1.6em;
		// border:1px solid #FFF;
		// color: #FFF;
		// position: absolute;
		top: 42vw;
		left: 10%;
		// z-index: 2;
	}




	.content-pickup_list-wrap {
		padding: 2vw 8vw 10vw;
		// 	background: rgba($main-color, .7);

		ul.content-pickup_list {
			// 		width: 100%;
			padding: 4vw 12vw;

			// 		margin: 0 auto;
			li {

				// 			width: 32.5%;
				// 			margin: 0 1vw;
				a {

					// 				width: 100%;
					// 				display: block;
					// 				position: relative;
					.content-pickup-photo {
						// 					width: 100%;
						height: 36vw;
						max-height: 140px;
						// 					position: relative;
						border-radius: 3vw;
						// 					overflow: hidden;
						// 					img{
						// 						width: 100%;
						// 						height: 100%;
						// 						object-fit:cover;
						// 						display: block;
						// 						transition: all .3s;
						// 					}
						// 					&::before{
						// 						content: '';
						// 						position: absolute;
						// 						background: linear-gradient(180deg, rgba(0,0,0,0) 10%, rgba(0,0,0,0.7) 100%);
						// 						width: 100%;
						// 						height: 100%;
						// 						top: 0;
						// 						left: 0;
						// 						z-index: 1;
						// 					}
					}

					// 				p.pickup-title{
					// 					position: absolute;
					// 					top: 50%;
					// 					left: 50%;
					// 					transform: translate(-50% , -50%);
					// 					font-size: calc(1.3rem + .3vw);
					// 					font-weight: 500;
					// 					color: #fff;
					// 					z-index: 1;
					// 				}
					// 				&:hover{
					// 					opacity: 1;
					// 					.content-pickup-photo{
					// 						img{
					// 							transform: scale(1.1);
					// 						}
					// 					}
					// 				}
				}
			}

			.slide-arrow {

				// 			position: absolute;
				// 			top: 50%;
				// 			transform: translateY(-50%);
				// 			z-index: 1;
				// 			cursor: pointer;
				// 			transition: all .4s;
				span {
					// 				position: relative;
					// 				display: block;
					// 				border: solid 1px #fff;
					width: 22px;
					height: 22px;

					// 				transition: all .4s;
					&::before {
						// 					content: '';
						// 					position: absolute;
						width: 8px;
						height: 8px;
						// 					top: 44%;
						// 					transform: translateY(-50%);
						// 					transition: all .4s;
					}
				}

				&.prev-arrow {

					// 				left: 2vw;
					span {
						&::before {
							right: 8px;
							// 						border-left: solid 1px #fff;
							// 						border-bottom: solid 1px #fff;
							// 						transform: rotate(45deg)  translateY(-50%);
						}
					}
				}

				&.next-arrow {

					// 				right: 2vw;
					span {
						&::before {
							left: 1px;
							// 						border-top: solid 1px #fff;
							// 						border-right: solid 1px #fff;
							// 						transform: rotate(45deg)  translateY(-50%);
						}
					}
				}

				// 			&:hover{
				// 				span{
				// 					background: #fff;
				// 				}
				// 				&.prev-arrow{
				// 					span{
				// 						&::before{
				// 							border-left: solid 1px $main-color;
				// 							border-bottom: solid 1px $main-color;
				// 						}
				// 					}
				// 				}
				// 				&.next-arrow{
				// 					span{
				// 						&::before{
				// 							border-top: solid 1px $main-color;
				// 							border-right: solid 1px $main-color;
				// 						}
				// 					}
				// 				}
				// 			}
			}
		}
	}

	.content-category-index {
		padding: 10vw 8vw;

		h3.category-list-title {
			// 		width: 100%;
			height: 12vw;
			// 		color: $main-color;
			// 		background: rgba(255, 255, 255, .7);
			border-radius: 6vw;
			// 		box-shadow: 8px 4px 10px rgba(23, 40, 139, .2);
			// 		display: flex;
			// 		align-items: center;
			// 		justify-content: center;
			font-size: 1.8rem;
			// 		font-weight: 500;
			margin-bottom: 4vw;
		}

		.category-list-wrap {
			padding: 2vw 0 6vw;

			h4.category-title {
				// 			border: 1px solid #FFF;
				font-size: 1.5rem;
				// 			font-weight: 500;
				// 			color: #FFF;
				height: 10vw;
				// 			display: flex;
				// 			align-items: center;
				// 			justify-content: center;
				margin-bottom: 3vw;
			}

			ul.category-list {

				// 			display: flex;
				// 			flex-wrap:wrap;
				// 			padding: 0 0 4vw;
				li {
					width: 100%;
					margin-right: 0;
					margin-bottom: 2vw;

					a {
						// 					width: 100%;
						min-height: 8vw;
						// 					display: flex;
						// 					align-items: center;
						// 					justify-content: center;
						font-size: 1.3rem;

						// 					letter-spacing: 0.02em;
						// 					color: $main-color;
						// 					background: #FFF;
						// 					position: relative;
						&::before {
							// 						content: '';
							width: 5vw;
							height: 5vw;
							// 						background: $main-color;
							// 						position: absolute;
							top: calc(50% - 2.5vw);
							right: 1.5vw;
						}

						&::after {
							// 						content: '';
							width: 2vw;
							height: 2vw;
							// 						border-right:1px solid #FFF;
							// 						border-bottom:1px solid #FFF;
							// 						position: absolute;
							top: calc(50% - 1vw);
							right: 3.5vw;
							// 						transform: rotate(-45deg);
						}
					}

					// 				&:nth-of-type(3n){
					// 					margin-right:0;
					// 				}
				}
			}
		}
	}

	// // ---------------------------------------------------------------------------

	// // 第三階層、第四階層 構造設定／content-wrap

	// // ---------------------------------------------------------------------------

	.content-wrap {

		// 	width: 88%;
		// 	display: block;
		// 	z-index:0;
		// 	margin: 0 auto;
		// 	position: relative;
		// 	padding-bottom: 8vh;
		// 	background: rgba(255, 255, 255, .85);
		&.data-content {
			// 		padding-top: 1vw;
			// 		min-height: 90vh;
			margin-top: 12vh;
		}
	}

	.content-inner {
		// 	width: 100%;
		padding: 4vw 6vw 6vh;
		// 	&.wd100{
		// 		width: 100%;
		// 		padding: 1vw 0 3vh;
		// 	}
	}


	// // ---------------------------------------------------------------------------

	// // 第三階層、第四階層 ニュース掲載フォーマット／content-newshead

	// // ---------------------------------------------------------------------------

	.content-newshead-wrap {
		// 	background: #FFF;
		padding: 2vw 8vw 3vw;

		.content-news-inner {
			// 		position: relative;
			// 		// background: rgba(#fff, .3);
			// 		// border-radius: 2vw;
			// 		margin: 2vw 0;
			padding: 4vw 6vw 6vw;
			flex-direction: column;

			// 		display: flex;
			// 		justify-content: space-between;
			// 		// box-shadow: 8px 4px 10px rgba($main-color, .2);
			.content-news-title-wrap {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 3vw;

				h3.content-news-title {
					line-height: 1.3em;
					// 				font-size: 3.5rem;
					// 				letter-spacing: .15em;
					// 				font-weight: 500;
					// 				font-family: Oswald, sans-serif;
				}
			}

			.content-news-wrap {
				width: 100%;

				ul.content-news-list {
					li {
						padding-bottom: 2vw;
						margin-bottom: 2vw;

						// 					border-bottom: solid 1px #000;
						a {
							// 						display: block;
							// 						color: #000;
							// 						font-size: calc(1rem + .3vw);
							line-height: 1.6em;

							// 						font-weight: 500;
							span {
								display: block;
								margin-bottom: 3px;
								// 							font-weight: 500;
								// 							font-family: Oswald, sans-serif;
								// 							margin-right: 3vw;
							}
						}

						// 					&:last-child{
						// 						margin-bottom: 0;
						// 					}
					}
				}
			}

			.content-news-more {
				padding-top: 0;

				a {
					font-size: 1.2rem;
					line-height: 1.6em;
					// 				position: relative;
					// 				display: block;
					width: 30vw;

					// 				max-width: 300px;
					// 				border: solid 1px #000;
					// 				color: #000;
					// 				padding: .5em;
					// 				text-align: center;
					// 				transition: all .3s;
					&::before {
						// 					content: '';
						// 					position: absolute;
						// 					background: #000;
						width: 6vw;
						// 					height: 1px;
						right: -3vw;
						// 					top: 50%;
						// 					transform: translateY(-50%);
					}

					// 				&:hover{
					// 					opacity: 1;
					// 					background: $main-color;
					// 				}
				}
			}
		}

		// 	& + .content-wrap {
		// 		padding-top: 3vw;
		// 	}
	}




	// // ---------------------------------------------------------------------------

	// // footer

	// // ---------------------------------------------------------------------------
	// footer {
	// 	position: relative;
	// 	z-index: 0;
	// }
	.footer {
		// 	width: 100%;
		// 	background: #FFF;
		padding: 6vw 8vw;

		// 	position: relative;
		.footer-content-wrap {
			flex-wrap: wrap;

			// 		display: flex;
			// 		justify-content: space-between;
			.footer-data-content {
				width: 80%;
				margin: 0 auto;

				// 			a.footer-logo {
				// 				width: 100%;
				// 				height: auto;
				// 				display: block;
				// 				img {
				// 					width: 100%;
				// 					height: auto;
				// 					display: block;
				// 				}
				// 			}
				p.add {
					text-align: center;
					// 				width: 95%;
					// 				margin-left: auto;
					font-size: 1.2rem;
					// 				letter-spacing: .02em;
					margin-bottom: 4vw;

					span {
						// 					display: block;
						font-size: 1.2rem;
						line-height: 1.5em;
						// 					font-weight: 500;
					}
				}

				ul.sns-links {
					width: 100%;
					// 				margin-left: auto;
					// 				display: flex;
					justify-content: center;

					li {
						width: 10vw;
						height: 10vw;
						margin-right: 3vw;

						// 					a {
						// 						width: 100%;
						// 						height: 100%;
						// 						display: block;
						// 						img {
						// 							display: block;
						// 							width: 100%;
						// 							height: 100%;
						// 							object-fit:contain;
						// 						}
						// 					}
						&:last-child {
							margin-right: 0;
						}
					}
				}

				ul.footer-icon-list {
					width: 97%;
					margin: 1vh auto;
					// display: flex;
					justify-content: center;

					li {
						width: 12vw;
						height: 12vw;
						margin-right: 1vw;
						overflow: hidden;
						// img {
						// 	display: block;
						// 	width: 100%;
						// 	height: 100%;
						// 	object-fit:contain;
						// }
					}
				}
			}

			ul.footer-main-links {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				padding: 6vw 0 0;

				li {
					width: 50%;
					margin-right: 0;
					margin-bottom: 0;

					a {

						// 					width: 100%;
						// 					display: block;
						svg {
							width: 80%;
							// 						display: block;
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}

			.footer-link-content {
				width: 100%;
				// 			display: flex;
				// 			justify-content: space-between;
				padding: 3vw 0 0;

				ul.footer-sub-links {
					width: 48%;

					li {
						// 					width: 100%;
						margin-bottom: .5em;
						line-height: 1.5em;

						a {
							// 						width: 100%;
							font-size: 1.2rem;
							line-height: 1.5em;
							// 						display: block;
						}
					}
				}
			}
		}
	}

	.footer-bottom-wrap {
		// background: #EFEFEF;
		// display: flex;
		// justify-content: space-between;
		flex-direction: column;
		padding: .4em 8vw .5em;

		.footer-bottom-navi {
			width: 100%;
			// display: flex;
			// justify-content: flex-start;
			flex-direction: column;

			li {
				margin-right: 1em;

				a {
					font-size: 1.2rem;
					// letter-spacing: .02em;
					// display: block;
					// width: fit-content;
				}
			}

		}

		p.copyright {
			margin-top: 1em;
			width: 100%;
			// font-size: 1.1rem;
			text-align: left;
			// position: relative;
		}
	}








	// // **********************************************

	// // 汎用アニメーション設定

	// // **********************************************

	// @keyframes pulldown {
	// 	0% {
	// 		opacity: 0;
	// 		transform: translateX(-100%);
	// 	}
	// 	100% {
	// 		opacity: 1;
	// 		transform: translateX(0%);
	// 	}
	// }

	// @keyframes pullup {
	// 	0% {
	// 		opacity: 1;
	// 		transform: translateX(0%);
	// 	}
	// 	100% {
	// 		opacity: 0;
	// 		transform: translateX(-100%);
	// 	}
	// }

	// .slide-up {
	// 	opacity: 0;
	// 	.is-show & {
	// 		animation: section_fade_in .8s ease-in-out both;
	// 	}
	// }

	// .slide-up02 {
	// 	opacity: 0;
	// 	.is-show & {
	// 		animation: section_fade_in02 .3s ease-in-out both;
	// 		transition-delay: .2s;
	// 	}
	// }

	// @keyframes section_fade_in {
	// 	0% {
	// 		opacity: 0;
	// 		transform: translateY(20px);
	// 	}
	// 	100% {
	// 		opacity: 1;
	// 		transform: translateY(0);
	// 	}
	// }

	// @keyframes section_fade_in02 {
	// 	0% {
	// 		opacity: .8;
	// 		transform: translateY(100px);
	// 	}
	// 	100% {
	// 		opacity: 1;
	// 		transform: translateY(0);
	// 	}
	// }



	// @keyframes fade_in {
	// 	0% {
	// 		opacity: 0;
	// 	}
	// 	100% {
	// 		opacity: 1;
	// 	}
	// }


	// @keyframes fade_left {
	// 	0% {
	// 		opacity: 0;
	// 		transform:translateX(-30%);
	// 	}
	// 	30% {
	// 		opacity: 1;
	// 	}
	// 	100% {
	// 		opacity: 1;
	// 		transform:translateX(0);
	// 	}
	// }
	// @keyframes fade_right {
	// 	0% {
	// 		opacity: 0;
	// 		transform:translateX(30%);
	// 	}
	// 	30% {
	// 		opacity: 1;
	// 	}
	// 	100% {
	// 		opacity: 1;
	// 		transform:translateX(0);
	// 	}
	// }
	// @keyframes fade_top {
	// 	0% {
	// 		opacity: 0;
	// 		transform:translateY(-10%);
	// 	}
	// 	30% {
	// 		opacity: 1;
	// 	}
	// 	100% {
	// 		opacity: 1;
	// 		transform:translateY(0);
	// 	}
	// }
	// @keyframes fade_bottom {
	// 	0% {
	// 		opacity: 0;
	// 		transform:translateY(10%);
	// 	}
	// 	70% {
	// 		opacity: 1;
	// 	}
	// 	100% {
	// 		opacity: 1;
	// 		transform:translateY(0);
	// 	}
	// }



	// // **********************************************

	// // 汎用設定

	// // **********************************************



	.anchor-link {
		padding-top: 12vh;
		margin-top: -12vh;
		// 	height: 0;
	}


	// .unpub-item {
	// 	pointer-events:none;
	// 	opacity: .3;
	// }
	// .unpub-hidden {
	// 	display: none;
	// }
	// .unpub-content {
	// 	position: relative;
	// 	pointer-events:none;
	// 	z-index:1;
	// 	&::before {
	// 		content : '';
	// 		width: 100%;
	// 		height: 100%;
	// 		background: rgba(#000, .7);
	// 		position: absolute;
	// 		top: 0;
	// 		left:0;
	// 		z-index: 5;
	// 	}
	// 	&::after {
	// 		content:'COMING SOON';
	// 		font-size: 1.6vw;
	// 			font-family: Roboto,sans-serif;
	// 		font-weight: 500;
	// 		color: #FFF;
	// 		position: absolute;
	// 		top: 50%;
	// 		left:50%;
	// 		transform: translate(-50%,-50%);
	// 		z-index: 8;
	// 	}
	// }
}


@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
	.header {
		padding: 0 16vw 0 0;

		.multi-lang {
			width: 9vw;

			.img {
				height: 6vw;
			}

			span {
				font-size: 1.2rem;
			}
		}

		&.hide-out {
			padding: 0 16vw 0 0;

			.multi-lang {
				.img {
					height: 6vw;
				}
			}
		}
	}

	ul.breadcrumb {
		li {
			padding: .3em 1.2em;

			a,
			p {
				font-size: calc(1rem + 1vw);
			}

			&:first-child {
				padding: .3em 1.2em .3em 0;
			}
		}
	}

	h2.content-title {
		font-size: calc(1.6rem + 2vw);
		line-height: 1.5em;
	}

	.content-header {
		.mainvisual {
			h2.mainvisual-category.fix {
				width: 16vw;
				top: 6.5vh;
				left: 4vw;
			}
		}

		.category-top & {
			.mainvisual {
				height: 58vw;
				padding: 28vw 24vw 0;

				&::after {
					height: 50vw;
				}
			}
		}
	}



	// // ---------------------------------------------------------------------------

	// // 第二階層 構造設定／pickup_list category-index

	// // ---------------------------------------------------------------------------

	.content-pickup_list-wrap {
		ul.content-pickup_list {
			li {
				a {
					.content-pickup-photo {
						max-height: 300px;
					}
				}
			}

			.slide-arrow {
				span {
					width: 30px;
					height: 30px;

					&::before {
						width: 12px;
						height: 12px;
					}
				}
			}
		}
	}

	.content-category-index {
		h3.category-list-title {
			font-size: calc(1.8rem + 1.5vw);
		}

		.category-list-wrap {
			h4.category-title {
				font-size: calc(1.4rem + 1vw);
			}

			ul.category-list {
				li {
					a {
						font-size: calc(1.3rem + .8vw);
					}
				}
			}
		}
	}


	// // ---------------------------------------------------------------------------

	// // 第三階層、第四階層 ニュース掲載フォーマット／content-newshead

	// // ---------------------------------------------------------------------------

	.content-newshead-wrap {

		// 	background: #FFF;
		// padding: 2vw 8vw 3vw;
		.content-news-inner {

			// 		position: relative;
			// 		// background: rgba(#fff, .3);
			// 		// border-radius: 2vw;
			// 		margin: 2vw 0;
			// padding: 4vw 6vw 6vw;
			// flex-direction: column;
			// 		display: flex;
			// 		justify-content: space-between;
			// 		// box-shadow: 8px 4px 10px rgba($main-color, .2);
			.content-news-title-wrap {

				// width: 100%;
				// display: flex;
				// justify-content: space-between;
				// align-items: center;
				// margin-bottom: 3vw;
				h3.content-news-title {
					// line-height: 1.3em;
					font-size: calc(2rem + 4vw);
					// 				letter-spacing: .15em;
					// 				font-weight: 500;
					// 				font-family: Oswald, sans-serif;
				}
			}

			.content-news-wrap {

				// width: 100%;
				ul.content-news-list {
					li {

						// padding-bottom: 2vw;
						// margin-bottom: 2vw;
						// 					border-bottom: solid 1px #000;
						a {
							// 						display: block;
							// 						color: #000;
							font-size: calc(1rem + 1vw);

							// line-height: 1.6em;
							// 						font-weight: 500;
							span {
								// display: block;
								// margin-bottom: 3px;
								// 							font-weight: 500;
								// 							font-family: Oswald, sans-serif;
								// 							margin-right: 3vw;
							}
						}

						// 					&:last-child{
						// 						margin-bottom: 0;
						// 					}
					}
				}
			}

			.content-news-more {

				// padding-top: 0;
				a {
					font-size: calc(1rem + 1vw);

					// line-height: 1.6em;
					// 				position: relative;
					// 				display: block;
					// width: 30vw;
					// 				max-width: 300px;
					// 				border: solid 1px #000;
					// 				color: #000;
					// 				padding: .5em;
					// 				text-align: center;
					// 				transition: all .3s;
					&::before {
						// 					content: '';
						// 					position: absolute;
						// 					background: #000;
						// width: 6vw;
						// 					height: 1px;
						// right: -3vw;
						// 					top: 50%;
						// 					transform: translateY(-50%);
					}

					// 				&:hover{
					// 					opacity: 1;
					// 					background: $main-color;
					// 				}
				}
			}
		}

		// 	& + .content-wrap {
		// 		padding-top: 3vw;
		// 	}
	}


	// // ---------------------------------------------------------------------------

	// // footer

	// // ---------------------------------------------------------------------------
	.footer {
		.footer-content-wrap {
			.footer-data-content {
				width: 70%;

				p.add {
					font-size: calc(1.2rem + .75vw);
					line-height: 1.8em;

					span {
						font-size: calc(1.2rem + 1vw);
						line-height: 1.8em;
					}
				}

				ul.sns-links {
					li {
						width: 7vw;
						height: 7vw;
					}
				}
			}

			ul.footer-main-links {
				li {
					width: 25%;

					a {
						svg {
							width: 100%;
						}
					}
				}
			}

			.footer-link-content {
				ul.footer-sub-links {
					li {
						a {
							font-size: calc(1.2rem + .7vw);
						}
					}
				}
			}
		}
	}

	ul.footer-main-links {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		padding: 6vw 0 0;

		li {
			width: 50%;
			margin-right: 0;
			margin-bottom: 0;

			&:last-child {
				margin-right: 0;
			}
		}
	}


}