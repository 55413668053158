@charset "UTF-8";
@import "variables";

@media screen and (max-width: 1024px) and (orientation: portrait) {

	//------------------------------------------------------------------------
	//side_hamburger_navi
	//-----------------------------------------------------------------------
	.hamburger-menu {
		//     position: fixed;
		top: 2vh;
		right: 5vw;
		width: 6vh;
		height: 6vh;

		//     cursor: pointer;
		//     transition: all .3s ease .05s;
		p.btn-border {

			//         width: 100%;
			//         height: 100%;
			//         margin: 0 auto;
			//         position: relative;
			span {
				//             position: absolute;
				//             left: .9vw;
				//             background: #fff;
				width: 5vh;

				//             height: 2px;
				//             transition: all .4s;
				//             display: block;
				&:nth-of-type(1) {
					top: calc(50% - 1.5vh);
				}

				//             &:nth-of-type(2){
				//                 top: 50% ;
				//             }
				&:nth-of-type(3) {
					top: calc(50% + 1.5vh);
				}
			}
		}

		//     .fix-menu {
		//         width: 100%;
		//         position: absolute;
		//         left: 0;
		//         bottom: 3vw;
		//         li {
		//             height: 6.5vw;
		//             a {
		//                 width: 100%;
		//                 height: 100%;
		//                 background: #FFF;
		//                 display: block;
		//                 padding: .5vw 0 1vw;
		//                 color: $main-color;
		//                 font-size: .8vw;
		//                 line-height: 1.3em;
		//                 text-align: center;
		//                 font-weight: 600;
		//                 &::before {
		//                     content:'';
		//                     width: 5vw;
		//                     height: 2.8vw;
		//                     margin: .2vw auto 0;
		//                     background: #DDD;
		//                     display: block;
		//                 }
		//             }
		//             &:nth-of-type(1) a::before {
		//                 background: url(../images/common/fix-icon01.svg)no-repeat center center;
		//                 background-size: contain;
		//             }
		//             &:nth-of-type(2) a {
		//                 border-top: 1px solid $main-color;
		//                 border-bottom: 1px solid $main-color;
		//                 &::before{
		//                     background: url(../images/common/fix-icon02.svg)no-repeat center center;
		//                     background-size: contain;
		//                     margin-bottom: .5vw;
		//                 }
		//             }
		//             &:nth-of-type(3) a::before{
		//                 background: url(../images/common/fix-icon03.svg)no-repeat center center;
		//                 background-size: contain;
		//             }
		//         }
		//     }
		&:hover {
			p.btn-border {
				span {
					left: .9vw;
					width: 4.5vh;

					&:nth-of-type(1) {
						top: calc(50% - 1.5vh);
					}

					&:nth-of-type(3) {
						top: calc(50% + 1.5vh);
					}
				}
			}
		}

		&.is-show {
			p.btn-border {
				span {

					// background: $main-color;
					&:nth-child(1) {
						transform: translate(0, 1.5vh) rotate(-37deg);
					}

					//                 &:nth-child(2){
					//                     opacity: 0;
					//                 }
					&:nth-child(3) {
						transform: translate(0, -1.5vh) rotate(37deg);
					}
				}
			}

			&:hover {
				p.btn-border {
					span {
						// background: $main-color;
						//                     background: #fff;
						//                     left: .9vw;
						width: 4.5vh;

						&:nth-of-type(1) {
							top: calc(50% - 1.5vh);
						}

						&:nth-of-type(3) {
							top: calc(50% + 1.5vh);
						}
					}
				}
			}
		}

		.hide-out & {
			top: 2vh;
			right: 5vw;
			width: 6vh;
			height: 6vh;
			//         p.btn-border{
			//             span{
			//                 background:#fff;
			//             }
			//         }
		}
	}






	.hamburger-menu-content-wrap {
		//     position: fixed;
		//     top: 0;
		//     right: -120%;
		//     width: 100%;
		//     height: 100vh;
		//     background: rgba(#FAFAFA, .9);
		//     background: linear-gradient(55deg, rgba(255,255,255,.9) 0%, rgba(238,241,251,.95) 55%, rgba(147,164,229,.95) 82%, rgba(152,192,226,1) 100%);

		//     opacity: 0;
		//     z-index: 18;
		//     display: flex;
		//     justify-content:space-between;
		flex-direction: column;
		padding: 10vh 8vw 8vw;
		overflow: scroll;

		//     transition:all .3s ease;
		ul.hamburger-menu-main-content {
			width: 100%;
			padding-top: 84vw;

			li {

				//             position: relative;
				a {
					//                 display: flex;
					//                 align-items:center;
					padding: 3vh 0 3vh 6vw;

					//                 border-bottom:1px solid #000;
					//                 position: relative;
					svg {
						height: 4vh;
					}

					span.en {
						// display: inline-block;
						// font-family: Oswald, sans-serif;
						// font-optical-sizing: auto;
						font-size: 1.25rem;
						// font-weight: 700;
						// letter-spacing: .01em;
						// line-height: 1.2em;
						text-align: right;
						margin-left: auto;
						margin-right: 17vw;
						// transition:all .3s ease;
					}

					&::before {
						//                     content:'';
						//                     display: block;
						width: 6vw;
						//                     height: 1px;
						//                     background: #000;
						//                     position: absolute;
						right: 6vw;
						//                     top: calc(50% - 1px);
						//                     transition:all .3s ease;
					}

					&::after {
						//                     content:'';
						//                     display: block;
						width: 3.5vw;
						height: 3.5vw;
						//                     border-right: 1px solid #000;
						//                     border-bottom: 1px solid #000;
						//                     transform: rotate(-45deg);
						//                     transform-origin:50% 50%;
						//                     position: absolute;
						right: calc(6vw + 1px);
						top: unset;
						//                     transition:all .3s ease;
					}

					&:hover {
						//                     opacity: 1;
						padding: 3vh 0 3vh 6vw;

						&::before {
							right: 6vw;
						}

						&::after {
							right: calc(6vw + 1px);
						}
					}
				}

				&:first-of-type {
					a {
						border-top: unset;

					}
				}

				&:last-child {
					a {
						border-bottom: unset;
					}
				}

				//             &::before {
				//                 content:'';
				//                 width: 0;
				//                 height: 100%;
				//                 background: rgba(#FFF, 1);
				//                 position: absolute;
				//                 top: 0;
				//                 left:0;
				//                 transition:all .3s ease;
				//             }
				//             &:hover {
				//                 &::before {
				//                     content:'';
				//                     width: 100%;
				//                 }
				//             }
			}
		}

		.hamburger-menu-sub-content {
			position: static;
			width: 100%;
			padding-top: 4vw;

			ul.hamburger-menu-pick-link {
				position: absolute;
				top: 27vw;
				left:8vw;
				width: calc(100% - 16vw);
				//             display: flex;
				justify-content: center;
				margin-bottom: 3vw;
				transform: translateY(0);
				//             li{
				//                 a{
				//                     display: block;
				//                     font-size: calc(1rem + .2vw);
				//                     font-weight: 500;
				//                     line-height: 1em;
				//                     color: $main-color;
				//                     background: #fff;
				//                     padding: .7em 1.8em;
				//                     border-radius: .3vw;
				//                     // border: solid 1px $main-color;
				//                     transition: all .3s;
				//                     &:hover{
				//                         opacity: 1;
				//                         background: $main-color;
				//                         color: #fff;
				//                     }
				//                 }
				//                 &:nth-of-type(1){
				//                     margin-right: 10px;
				//                 }
				//             }
			}

			ul.hamburger-menu-target-link {
				position: absolute;
				top: 46vw;
				left:7vw;
				width: calc(100% - 14vw);

				//             display: flex;
				//             flex-wrap:wrap;
				//             justify-content:space-between;
				//             margin-bottom: 2vh;
				li {

					//                 width: 49%;
					//                 margin-bottom: 2vh;
					a {
						//                     width: 100%;
						//                     height: 8vh;
						max-height: 16vw;

						//                     color: $main-color;
						//                     background: rgba(#FFF, .45);
						//                     border-radius:4vh;
						//                     box-shadow: 8px 4px 10px rgba($main-color, .2);
						//                     display: flex;
						//                     align-items:center;
						//                     justify-content:center;
						//                     // padding-left: 1.5vw;
						//                     transition:all .2s ease;
						//                     span {
						//                         display: block;
						//                         width: 20%;
						//                         img{
						//                             width: 100%;
						//                             display: block;
						//                         }
						//                     }
						//                     p{
						//                         width: 70%;
						//                         text-align: center;
						//                         line-height: 1.2em;
						//                         font-size: calc(1.2rem + .3vw);
						//                         font-weight: 600;
						//                     }
						&:hover {
							background: #fff;
							color: $main-color;
							//                         opacity: 1;
						}

						//                     // &::after {
						//                     // 	content:'';
						//                     // 	width: 1.5vw;
						//                     // 	height: 1.5vw;
						//                     // 	border-right: 1px solid #FFF;
						//                     // 	border-bottom: 1px solid #FFF;
						//                     // 	position: absolute;
						//                     // 	top: 50%;
						//                     // 	left: 50%;
						//                     // 	transform:rotate(-45deg) translate(-20%,-90%);
						//                     // }
					}
				}
			}

			.hamburger-menu-search-area {

				//             margin-bottom: 2.5vh;
				#cse-search-box {
					input[type="text"] {
						width: calc(100% - 10vw);
						min-height: 5vh;
						//                     border: unset;
						//                     margin-left: .2vw;
					}

					input[type="image"] {
						width: 8vw;
						height: 6vw;
					}
				}
			}

			.hamburger-menu-other-link-wrap {

				//             margin-bottom: 4vh;
				//             padding: 0 .3vw;
				ul.hamburger-menu-other-link {

					//                 display: flex;
					//                 justify-content: space-between;
					//                 flex-wrap: wrap;
					li {

						//                     width: 60%;
						//                     a {
						//                         position: relative;
						//                         display: block;
						//                         font-size: calc(1rem + .3vw);
						//                         font-weight: 600;
						//                         padding-left: 15px;
						//                         transition: all .3s;
						//                         &::before{
						//                             content: '';
						//                             position: absolute;
						//                             width: 7px;
						//                             height: 7px;
						//                             border-right: 1.5px solid $main-color;
						//                             border-bottom: 1.5px solid $main-color;
						//                             top: 50%;
						//                             left: 0;
						//                             transform:rotate(-45deg) translateY(-50%);
						//                         }
						//                         &.en{
						//                             letter-spacing: .05em;
						//                         }
						//                         &:hover{
						//                             opacity: 1;
						//                             margin-left: 10px;
						//                         }
						//                     }
						//                     &:nth-of-type(2n){
						//                         width: 38%;
						//                     }
						&:last-child {
							width: 100%;
						}
					}
				}
			}

			.hamburger-menu-request-link-wrap {

				//             display: flex;
				//             justify-content: space-between;
				//             align-items: center;
				a.hamburger-menu-request-link {
					//                 display: block;
					width: 55%;
					//                 background: $main-color;
					//                 color: #fff;
					//                 font-size: calc(1.2rem + .2vw);
					//                 font-weight: 600;
					//                 text-align: center;
					//                 padding: .7em;
					//                 min-height: 6vh;
					border-radius: 6vw;
					//                 box-shadow: 8px 4px 10px rgba($main-color, .2);
					//                 transition: all .3s;
					//                 &:hover{
					//                     background: #fff;
					//                     color: $main-color;
					//                 }
				}

				ul.hamburger-menu-sns-link {
					//                 display: flex;
					//                 justify-content: space-between;
					//                 align-items: center;
					width: 38%;

					li {
						//                     width: 23%;
						//                     a {
						//                         display: block;
						//                         transition: all .3s;
						//                         img {
						//                             width: 100%;
						//                         }
						//                         &:hover{
						//                             opacity: 1;
						//                             transform:scale(1.1);
						//                         }
						//                     }
					}
				}
			}
		}

		//     &.is-show{
		//         opacity: 1;
		//         right: 0;
		//         transition:all .5s ease;
		//     }
	}












	// .global-menu-submenu-wrap {
	//     .global-menu-submenu {
	//         position: fixed;
	//         top: 0;
	//         left: -100%;
	//         width: 65%;
	//         height: 100vh;
	//         background: $main-gray;
	//         box-shadow: 0 0 12px rgba(0,0,0,.1);
	//         opacity: 0;
	//         transition: all .4s;
	//         z-index: 16;
	//         padding: 8vw 0 4vw 8vw;
	//         display: flex;
	//         flex-wrap:wrap;
	//         justify-content:space-between;
	//         overflow-Y:auto;

	//         dl{
	//             width: 32%;
	//             margin-bottom: 3vh;
	//             dt,dd{
	//                 a{
	//                     display: block;
	//                 }
	//             }
	//             dt{
	//                 margin-bottom: 2vh;
	//                 a{
	//                     font-size: 1.5rem;
	//                     font-weight: 500;
	//                     padding-bottom: 2vh;
	//                     position: relative;
	//                     z-index: 1;
	//                     &::before{
	//                         content: '';
	//                         position: absolute;
	//                         background: #fff;
	//                         width: 80%;
	//                         height: 4px;
	//                         bottom: 0;
	//                         left: 0;
	//                         z-index: -1;
	//                         transition: all .4s;
	//                     }
	//                     &::after{
	//                         z-index: -1;
	//                     }
	//                     &:hover{
	//                         opacity: 1;
	//                         &::before{
	//                             // bottom: 1vh;
	//                         }
	//                         &:after{
	//                             animation: fade_menu_top 1.5s ease .2s;
	//                         }
	//                     }
	//                 }
	//             }
	//             dd{
	//                 margin-bottom: 1vh;
	//                 a{
	//                     font-size: 1.5rem;
	//                     transition: all .4s;
	//                     &.ad-link{
	//                         border: solid 1px #fff;
	//                         padding: 10px 2vw;
	//                         transition: all .4s;
	//                     }
	//                     &:hover{
	//                         opacity: 1;
	//                         padding-left: 1vw;
	//                         &.ad-link{
	//                             padding: 10px 2vw;
	//                             background: #fff;
	//                             color: $main-color;
	//                         }
	//                     }
	//                 }
	//             }
	//         }
	//         &.is-show{
	//             opacity: 1;
	//             left: 30%;
	//         }
	//     }
	// }

	// @keyframes fade_menu_top {
	//     0% {
	//         opacity: 0;
	//         transform:translateY(-30%);
	//     }
	//     60% {
	//         opacity: 1;
	//     }
	//     100% {
	//         opacity: 1;
	//         transform: translateY(-10%);
	//     }
	// }

	// .side-fix-menu-wrap {
	//     width: 4vw;
	//     height: 88vh;
	//     position: fixed;
	//     top: 10vh;
	//     right: 0;
	//     z-index: 20;
	//     ul.side-fix-menu {
	//         width: 3.4vw;
	//         height: 75%;
	//         margin-left: auto;
	//         display: flex;
	//         flex-direction:column;
	//         justify-content: space-between;
	//         align-items: flex-end;
	//         li {
	//             width: 100%;
	//             height: 31%;
	//             border-radius:.8vw 0 0 .8vw;
	//             overflow: hidden;
	//             a {
	//                 writing-mode: vertical-rl;
	//                 display: flex;
	//                 justify-content: center;
	//                 align-items: center;
	//                 color: #FFF;
	//                 width: 100%;
	//                 height: 100%;
	//                 background: $main-color;
	//                 font-size: calc(1rem + .2vw);
	//                 font-weight: 500;
	//                 letter-spacing: .2em;
	//             }
	//             &:nth-of-type(1){
	//                 height: 25%;
	//             }
	//             &:nth-of-type(2){
	//                 height: 36%;
	//             }
	//             &:nth-of-type(3){
	//                 height: 36%;
	//                 a{
	//                     text-orientation: upright;
	//                     background: $main-color;
	//                     span{
	//                         padding-left: 6px;
	//                     }
	//                 }
	//             }
	//         }
	//     }
	//     ul.side-sns-menu {
	//         width: 2.5vw;
	//         height: 25%;
	//         margin: 0 auto;
	//         display: flex;
	//         flex-direction:column;
	//         justify-content: center;
	//         align-items: center;
	//         li {
	//             height: 2.5vw;
	//             margin: .5vw 0;
	//             a {
	//                 width: 100%;
	//                 height: 100%;
	//                 display: block;
	//                 .sns-icon{
	//                     background: url(../images/common/sns-icon01.svg) no-repeat center center;
	//                     background-size: contain;
	//                     width: 3vw;
	//                     height: 3vw;
	//                 }
	//             }
	//             &:nth-of-type(2){
	//                 a{
	//                     .sns-icon{
	//                         background: url(../images/common/sns-icon02.svg) no-repeat center center;
	//                         background-size: contain;
	//                     }
	//                 }
	//             }
	//         }
	//         &.is-show{
	//             li{
	//                 a{
	//                     .sns-icon{
	//                         background: url(../images/common/sns-icon01-white.svg) no-repeat center center;
	//                         background-size: contain;
	//                     }
	//                 }
	//                 &:nth-of-type(2){
	//                     a{
	//                         .sns-icon{
	//                             background: url(../images/common/sns-icon02-white.svg) no-repeat center center;
	//                             background-size: contain;
	//                         }
	//                     }
	//                 }
	//             }
	//         }
	//     }
	// }

}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
	.hamburger-menu-content-wrap {
		ul.hamburger-menu-main-content {
			li {
				a {
					padding: 3vh 0 3vh 4vw;

					svg {
						height: 4vh;
					}

					span.en {
						font-size: 2rem;
						text-align: right;
						margin-left: auto;
						margin-right: 17vw;
					}
				}
			}
		}

		.hamburger-menu-sub-content {
			ul.hamburger-menu-pick-link {
				li {
					a {
						height: 6vh;
						font-size: calc(1rem + .7vw);
						padding: 1em 1.8em;
						border-radius: 6vw;
					}
				}
			}

			ul.hamburger-menu-target-link {
				li {
					a {
						height: 10vh;
						max-height: 80px;

						p {
							font-size: calc(1.2rem + .8vw);
						}
					}
				}
			}

			.hamburger-menu-other-link-wrap {
				ul.hamburger-menu-other-link {
					li {
						a {
							font-size: calc(1rem + .8vw);
							line-height: 1.8em;
						}
					}
				}
			}

			.hamburger-menu-request-link-wrap {
				a.hamburger-menu-request-link {
					width: 60%;
					font-size: calc(1.2rem + 1vw);
					padding: 1em;
					min-height: 4vh;
				}

				ul.hamburger-menu-sns-link {
					width: 32%;
				}
			}
		}
	}
}