@charset "UTF-8";
@import "variables";
@import "common";
@import "module";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&family=Noto+Serif+JP:wght@400;500;600&family=Oswald:wght@400;500;600&family=Zen+Kaku+Gothic+New:wght@300;400;500;700&display=swap');
// font-family: 'Noto Sans JP', sans-serif;
// font-family: 'Noto Serif JP', serif;
// font-family: "Zen Kaku Gothic New", sans-serif;
// font-family: "Oswald", sans-serif;
// font-optical-sizing: auto;




// ---------------------------------------------------------------------------

// body

// ---------------------------------------------------------------------------

body {
	font-family: 'Noto Sans JP', sans-serif;
	font-family: "Zen Kaku Gothic New", sans-serif;
	font-size: calc(1.3rem + .25vw);
	font-weight: 400;
	line-height: 1.8em;
	letter-spacing: .05em;
	width: 100%;

	.fix-bg {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: url(../images/common/content-bg.webp)no-repeat center center;
		background-size: cover;
		z-index: -1;
	}

	&.admission .fix-bg {
		background: url(../images/target/target-admission-main_bg.webp)no-repeat center center;
		background-size: cover;
	}
}


// ---------------------------------------------------------------------------

// header／nav

// ---------------------------------------------------------------------------


.header {
	width: 100%;
	height: 7vw;
	padding: 2vw 8.5vw 0 0;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 20;
	// background: #FFF;
	transition: all .4s ease;

	.header-logo {
		width: fit-content;
		height: 5vw;
		display: flex;
		align-items: center;
		background: $main-color;
		transition: all .4s ease;

		img {
			height: 100%;
			display: block;
		}
	}

	.multi-lang {
		width: 7vw;
		display: block;

		.img {
			width: 100%;
			height: 2.4vw;
			transition: all .4s ease;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				display: block;
			}
		}

		span {
			display: block;
			font-size: calc(.8rem + .3vw);
			line-height: 1.2em;
			letter-spacing: 0;
			color: #FFF;
			text-align: center;
			font-weight: 500;
		}
	}

	&::before {
		content: '';
		width: 100%;
		height: 5vw;
		background: $main-color;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		opacity: 0;
		transform: translateY(-4vw);
		pointer-events: none;
		transition: all .2s ease;
	}

	&.hide-out {
		height: 5vw;
		padding: 0 8.5vw 0 0;

		// background: rgba(#FFF, .85);
		// background: $main-color;
		.header-logo {
			height: 5vw;
		}

		.multi-lang {
			.img {
				height: 2.2vw;
			}
		}

		.header-menu {
			li {
				a {
					padding: .2vw 1vw;
					font-size: 1vw;
				}
			}
		}

		&::before {
			content: '';
			opacity: 1;
			transform: translateY(0);
			transition: all .2s ease .2s;
		}
	}
}



//------------------------------------------------------------------------
//side_hamburger_navi
//------------------------------------------------------------------------
@import "menu/_hamburger_navi";





// ---------------------------------------------------------------------------

// content-header／mainvisual

// ---------------------------------------------------------------------------



.content-header {
	.mainvisual {
		width: 100%;
		height: 24vw;
		padding: 14vw 32vw 0;

		h2.mainvisual-category {
			width: 100%;
			position: relative;
			z-index: 5;

			img {
				width: 100%;
				display: block;
			}

			&.fix {
				width: 11vw;
				position: fixed;
				margin: 0;
				top: 1.5vw;
				left: 20.5vw;
				z-index: 100;
				animation: fade_top .7s ease .4s both;
			}
		}

		.education & {
			background: url(../images/mainvisual/mainvisual-education.webp) no-repeat center center;
			background-size: cover;
		}

		.research & {
			background: url(../images/mainvisual/mainvisual-research.webp) no-repeat center center;
			background-size: cover;
		}

		.campus & {
			background: url(../images/mainvisual/mainvisual-campus.webp) no-repeat center center;
			background-size: cover;
		}

		.about & {
			background: url(../images/mainvisual/mainvisual-about.webp) no-repeat center center;
			background-size: cover;
		}
	}

	.category-top & {
		.mainvisual {
			height: 32vw;
			position: relative;

			&::after {
				content: '';
				width: 100%;
				height: 20vw;
				position: absolute;
				bottom: 0;
				left: 0;
				background: #DDD;
				background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(221, 221, 221, 1) 100%);

				mix-blend-mode: multiply;
				z-index: 1;
			}
		}
	}
}

ul.breadcrumb {
	width: 100%;
	padding: 0;
	display: flex;

	li {
		padding: .3em 1.2em;
		position: relative;

		&::before {
			content: '';
			width: .7vw;
			height: .7vw;
			border-right: 1px solid #FFF;
			border-bottom: 1px solid #FFF;
			position: absolute;
			top: calc(50% - .275vw);
			right: 0;
			transform: rotate(-45deg);
		}

		a,
		p {
			color: #fff;
			display: block;
			font-size: 1.4rem;
			font-weight: 500;
		}

		&:first-child {
			padding: .3em 1.2em .3em 0;
		}

		&:last-child {
			&::before {
				content: unset;
			}
		}
	}

	.content-wrap & {
		padding: 1vw 3vw;

		li {

			a,
			p {
				color: #000;
			}

			&::before {
				border-right: 1px solid #000;
				border-bottom: 1px solid #000;
			}
		}
	}
}

h2.content-title {
	font-size: 3.4rem;
	font-weight: 700;
	padding: .6em 0 .8em;
	text-align: center;
}






// ---------------------------------------------------------------------------

// 第二階層 構造設定／pickup_list category-index

// ---------------------------------------------------------------------------
.content-category-introduction {
	width: 58%;
	margin: 0 auto;
	padding: 2vh 4vw;
	font-size: 1.7rem;
	line-height: 1.6em;
	border: 1px solid #FFF;
	color: #FFF;
	position: absolute;
	top: 22vw;
	left: 21%;
	z-index: 2;
}


.content-pickup_list-wrap {
	padding: 1vw 8vw;
	background: rgba($main-color, .7);

	ul.content-pickup_list {
		width: 100%;
		padding: 2vw 8vw 3vw;
		margin: 0 auto;

		li {
			width: 32.5%;
			margin: 0 1vw;

			a {
				width: 100%;
				display: block;
				position: relative;

				.content-pickup-photo {
					width: 100%;
					height: 12vw;
					position: relative;
					border-radius: .5vw;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						display: block;
						transition: all .3s;
					}

					&::before {
						content: '';
						position: absolute;
						background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.7) 100%);
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						z-index: 1;
					}
				}

				p.pickup-title {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: calc(1.3rem + .3vw);
					font-weight: 500;
					color: #fff;
					z-index: 1;
				}

				&:hover {
					opacity: 1;

					.content-pickup-photo {
						img {
							transform: scale(1.1);
						}
					}
				}
			}
		}

		.slide-arrow {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
			cursor: pointer;
			transition: all .4s;

			span {
				position: relative;
				display: block;
				border: solid 1px #fff;
				width: 30px;
				height: 30px;
				transition: all .4s;

				&::before {
					content: '';
					position: absolute;
					width: 10px;
					height: 10px;
					top: 44%;
					transform: translateY(-50%);
					transition: all .4s;
				}
			}

			&.prev-arrow {
				left: 2vw;

				span {
					&::before {
						right: 10px;
						border-left: solid 1px #fff;
						border-bottom: solid 1px #fff;
						transform: rotate(45deg) translateY(-50%);
					}
				}
			}

			&.next-arrow {
				right: 2vw;

				span {
					&::before {
						left: 3px;
						border-top: solid 1px #fff;
						border-right: solid 1px #fff;
						transform: rotate(45deg) translateY(-50%);
					}
				}
			}

			&:hover {
				span {
					background: #fff;
				}

				&.prev-arrow {
					span {
						&::before {
							border-left: solid 1px $main-color;
							border-bottom: solid 1px $main-color;
						}
					}
				}

				&.next-arrow {
					span {
						&::before {
							border-top: solid 1px $main-color;
							border-right: solid 1px $main-color;
						}
					}
				}
			}
		}
	}
}

.content-category-index {
	padding: 5vw 8vw 6vw;

	h3.category-list-title {
		width: 100%;
		height: 5vw;
		color: $main-color;
		background: rgba(255, 255, 255, .7);
		border-radius: 2.5vw;
		box-shadow: 8px 4px 10px rgba(23, 40, 139, .2);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 2rem;
		font-weight: 500;
		margin-bottom: 2vw;
	}

	.category-list-wrap {
		padding: 2vw 2vw 6vw;

		h4.category-title {
			border: 1px solid #FFF;
			font-size: 1.7rem;
			font-weight: 500;
			color: #FFF;
			height: 4vw;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 2vw;
		}

		h4.category-line-title {
			color: #FFF;
			width: 100%;
			height: 4vw;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 2vw;
			span {
				width: fit-content;
				font-size: 1.7rem;
				font-weight: 500;
				display: block;
				flex-grow:0;
				padding:0 1em;

			}
			&::before {
				content: '';
				flex-grow:1;
				width: auto;
				height: 2px;
				background: #FFF;
				display: block;
			}
			&::after {
				content: '';
				flex-grow: 1;
				height: 2px;
				background: #FFF;
				display: block;
			}
		}

		ul.category-list {
			display: flex;
			flex-wrap: wrap;
			padding: 0 0 4vw;

			li {
				width: 32%;
				margin-right: 2%;
				margin-bottom: 1vw;

				a {
					width: 100%;
					min-height: 4vw;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 1.6rem;
					letter-spacing: 0.02em;
					color: $main-color;
					background: #FFF;
					position: relative;
					padding: .5em 2.5em .5em 1em;
					height: 100%;

					&::before {
						content: '';
						width: 1.2vw;
						height: 1.2vw;
						background: $main-color;
						position: absolute;
						top: calc(50% - .6vw);
						right: 1vw;
					}

					&::after {
						content: '';
						width: .4vw;
						height: .4vw;
						border-right: 1px solid #FFF;
						border-bottom: 1px solid #FFF;
						position: absolute;
						top: calc(50% - .2vw);
						right: 1.5vw;
						transform: rotate(-45deg);
					}
				}

				&:nth-of-type(3n) {
					margin-right: 0;
				}
			}
		}
	}
}

// ---------------------------------------------------------------------------

// 第三階層、第四階層 構造設定／content-wrap

// ---------------------------------------------------------------------------

.content-wrap {
	width: 88%;
	display: block;
	z-index: 0;
	margin: 0 auto;
	position: relative;
	padding-bottom: 8vh;
	background: rgba(255, 255, 255, .85);

	&.data-content {
		padding-top: 1vw;
		min-height: 90vh;
		margin-top: 10vw;
	}
}

.content-inner {
	width: 100%;
	padding: 1vw 8vw 3vh;

	&.wd100 {
		width: 100%;
		padding: 1vw 0 3vh;
	}
}


// ---------------------------------------------------------------------------

// 第三階層、第四階層 ニュース掲載フォーマット／content-newshead

// ---------------------------------------------------------------------------

.content-newshead-wrap {
	background: #FFF;
	padding: 1vw 8vw 3vw;

	.content-news-inner {
		position: relative;
		// background: rgba(#fff, .3);
		// border-radius: 2vw;
		margin: 2vw 0;
		// padding: 4.5vw 4vw 4vw;
		display: flex;
		justify-content: space-between;

		// box-shadow: 8px 4px 10px rgba($main-color, .2);
		.content-news-title-wrap {
			width: 25%;

			h3.content-news-title {
				font-size: 3.5rem;
				letter-spacing: .15em;
				font-weight: 500;
				font-family: Oswald, sans-serif;
			}
		}

		.content-news-wrap {
			width: 75%;

			ul.content-news-list {
				li {
					padding-bottom: .7vw;
					margin-bottom: .7vw;
					border-bottom: solid 1px #000;

					a {
						display: block;
						color: #000;
						font-size: calc(1rem + .3vw);
						font-weight: 500;

						span {
							font-weight: 500;
							font-family: Oswald, sans-serif;
							margin-right: 3vw;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		.content-news-more {
			padding-top: 5vw;

			a {
				position: relative;
				display: block;
				width: 15vw;
				max-width: 300px;
				border: solid 1px #000;
				color: #000;
				padding: .5em;
				text-align: center;
				transition: all .3s;

				&::before {
					content: '';
					position: absolute;
					background: #000;
					width: 3vw;
					height: 1px;
					right: -1.5vw;
					top: 50%;
					transform: translateY(-50%);
				}

				&:hover {
					opacity: 1;
					background: $main-color;
				}
			}
		}
	}

	&+.content-wrap {
		padding-top: 3vw;
	}
}




// ---------------------------------------------------------------------------

// footer

// ---------------------------------------------------------------------------
footer {
	position: relative;
	z-index: 0;
}

.footer {
	width: 100%;
	background: #FFF;
	padding: 3vw 8vw;
	position: relative;

	.footer-content-wrap {
		display: flex;
		justify-content: space-between;

		.footer-data-content {
			width: 32%;

			a.footer-logo {
				width: 100%;
				height: auto;
				display: block;

				img {
					width: 100%;
					height: auto;
					display: block;
				}
			}

			p.add {
				width: 95%;
				margin-left: auto;
				font-size: 1.4rem;
				letter-spacing: .02em;
				margin-bottom: 2vw;

				span {
					display: block;
					font-size: 1.5rem;
					font-weight: 500;
				}
			}

			ul.sns-links {
				width: 97%;
				margin-left: auto;
				display: flex;
				justify-content: flex-start;
				margin-bottom: 1.5vw;

				li {
					width: 3vw;
					height: 3vw;
					margin-right: 1vw;

					a {
						width: 100%;
						height: 100%;
						display: block;

						img {
							display: block;
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
				}
			}

			ul.footer-icon-list {
				width: 97%;
				margin-left: auto;
				display: flex;
				justify-content: flex-start;

				li {
					width: 5vw;
					height: 5vw;
					margin-right: 1vw;
					overflow: hidden;

					img {
						display: block;
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}

		ul.footer-main-links {
			width: 24%;
			padding: 2.2vw 0 0 4vw;

			li {
				width: 80%;
				margin-bottom: 1vw;

				a {
					width: 100%;
					display: block;
					padding: 1vw 0 0 .6vw;

					svg {
						width: 80%;
						display: block;
						transition: all .3s ease;
					}

					&:hover {
						opacity: 1;
						background: #EFEFEF;

						svg {
							transform: translateX(.4vw);
						}
					}
				}
			}
		}

		.footer-link-content {
			width: 44%;
			display: flex;
			justify-content: space-between;
			padding: 2vw 4vw 0 0;

			ul.footer-sub-links {
				width: 46%;

				li {
					width: 100%;
					margin-bottom: .5em;

					a {
						width: 100%;
						font-size: 1.5rem;
						font-weight: 400;
						display: block;
						transition: all .3s ease;

						&:hover {
							opacity: 1;
							color: $main-color;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}

.footer-bottom-wrap {
	background: #EFEFEF;
	display: flex;
	justify-content: space-between;
	padding: .4em 8vw .6em;

	.footer-bottom-navi {
		width: 50%;
		display: flex;
		justify-content: flex-start;

		li {
			margin-right: 1em;

			a {
				font-size: 1.4rem;
				letter-spacing: .02em;
				display: block;
				width: fit-content;
			}
		}

	}

	p.copyright {
		width: 30%;
		font-size: 1.1rem;
		text-align: right;
		position: relative;
	}
}





// **********************************************

// 汎用アニメーション設定

// **********************************************

@keyframes pulldown {
	0% {
		opacity: 0;
		transform: translateX(-100%);
	}

	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}

@keyframes pullup {
	0% {
		opacity: 1;
		transform: translateX(0%);
	}

	100% {
		opacity: 0;
		transform: translateX(-100%);
	}
}

.slide-up {
	opacity: 0;

	.is-show & {
		animation: section_fade_in .8s ease-in-out both;
	}
}

.slide-up02 {
	opacity: 0;

	.is-show & {
		animation: section_fade_in02 .3s ease-in-out both;
		transition-delay: .2s;
	}
}

@keyframes section_fade_in {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes section_fade_in02 {
	0% {
		opacity: .8;
		transform: translateY(100px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}



@keyframes fade_in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}


@keyframes fade_left {
	0% {
		opacity: 0;
		transform: translateX(-30%);
	}

	30% {
		opacity: 1;
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fade_right {
	0% {
		opacity: 0;
		transform: translateX(30%);
	}

	30% {
		opacity: 1;
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fade_top {
	0% {
		opacity: 0;
		transform: translateY(-10%);
	}

	30% {
		opacity: 1;
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fade_bottom {
	0% {
		opacity: 0;
		transform: translateY(10%);
	}

	70% {
		opacity: 1;
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}



// **********************************************

// 汎用設定

// **********************************************



.anchor-link {
	padding-top: 5vw;
	margin-top: -5vw;
	height: 0;
}


.unpub-item {
	pointer-events: none;
	opacity: .3;
}

.unpub-hidden {
	display: none;
}

.unpub-content {
	position: relative;
	pointer-events: none;
	z-index: 1;

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background: rgba(#000, .7);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
	}

	&::after {
		content: 'COMING SOON';
		font-size: 1.6vw;
		font-family: Roboto, sans-serif;
		font-weight: 500;
		color: #FFF;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 8;
	}
}

@import "style-res";