@charset "UTF-8";
@import "variables";

// ---------------------------------------------------------------------------

// レイアウトフォーマット

// ---------------------------------------------------------------------------

// /* title h3 h4 h5 h6 */

h3.section-title {
	width: 100%;
	padding: 1em 1.5em;
	color: #fff;
	font-size: 2rem;
	font-weight: 500;
	line-height: 1em;
	letter-spacing: .02em;
	margin: 0 0 2vw;
	background: linear-gradient(110deg, #769de7 0, #5f64cb 35%, #6f93cf 60%, #667dd6 100%);
	transition: all .3s ease;
}


h4.section-title {
	font-size: 1.9rem;
	font-weight: 500;
	line-height: 1.6em;
	letter-spacing: .02em;
	padding: .2em 0 .2em 1.6em;
	position: relative;
	margin-bottom: 1vw;

	&::before {
		content: '';
		display: block;
		width: 2px;
		height: 100%;
		background: $main-color;
		position: absolute;
		top: 0;
		left: 0;
	}
}


h4.border-title {
	font-size: 1.8rem;
	font-weight: 500;
	line-height: 1.2em;
	letter-spacing: .02em;
	padding: .7em .3em;
	text-align: center;
	color: #000;
	border: 1px solid #000;
	margin: 1.5em 0 1em;
}


h5.bold-title {
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 1.4em;
	letter-spacing: .02em;
	padding: .6em 0 .6em;

	&.center {
		text-align: center;
	}
}


h5.box-title {
	font-size: 1.6rem;
	font-weight: 500;
	line-height: 1.4em;
	letter-spacing: .02em;
	padding: .3em 0 .6em 1.4em;
	position: relative;

	&::before {
		content: '';
		display: block;
		width: 1.1em;
		height: 1.1em;
		background: $main-color;
		position: absolute;
		top: calc(50% - .6em);
		left: 0;
	}
}

// /* page-lead */

.lead-copy {
	width: 100%;
	margin: 0 auto;
	padding: 2vh 0 4vh;
	color: $main-color;
	font-size: 3rem;
	line-height: 1.9em;
	letter-spacing: .05em;
}




// /* p 本文設定*/

.paragraph {
	display: block;
	margin-bottom: 1.5em;
	font-size: 1.5rem;
	line-height: 1.9em;

	&.align-right {
		text-align: right;
	}

	&.align-center {
		text-align: center;
	}

	&.large-text {
		font-size: 1.9rem;
	}

	&.small-text {
		font-size: 1.3rem;
		line-height: 1.7em;
	}

	&.red {
		color: red;
		text-decoration: underline;
	}

	>a {
		display: inline-block;
		color: $main-color;
		border-bottom: 1px dotted $main-color;
		padding: 0 .2em;
		font-weight: 500;
	}

}



// /* リンクボタン */

a.link-btn {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: fit-content;
	min-height: 3.2em;
	border-radius: 1.6em;
	padding: 0 5vw 0 1.5em;
	margin: 2vh 0 3vh;
	color: #FFF;
	// text-decoration:underline;
	font-size: 1.5rem;
	position: relative;
	background: $main-color;
	background: linear-gradient(90deg, rgba(24, 38, 137, 1) 0%, rgba(80, 133, 192, 1) 70%, rgba(32, 84, 191, 1) 100%);

	transition: all .3s ease;

	&::after {
		content: "";
		display: block;
		width: 1vw;
		height: 1vw;
		border-right: 1px solid #FFF;
		border-bottom: 1px solid #FFF;
		transform: rotate(-45deg);
		transform-origin: 50% 50%;
		position: absolute;
		right: calc(1vw + 1px);
		top: calc(50% - .5vw);
		transition: all .3s ease;
	}

	&::before {
		content: "";
		display: block;
		width: 1.6vw;
		height: 1px;
		background: #FFF;
		position: absolute;
		right: 1vw;
		top: 50%;
		transition: all .3s ease;
	}

	&:hover {
		background: $main-color;
		opacity: 1;
	}
}

a[target="_blank"].link-btn {
	&::before {
		display: none;
	}

	&::after {
		width: 2.8vw;
		height: 2.8vw;
		top: calc(50% - 1.4vw);
		right: .8vw;
		border-right: none;
		border-bottom: none;
		transform: rotate(0);
		background: url(../images/common/arrow_out_white.svg) no-repeat center center;
	}
}

a[href$=".pdf"].link-btn {
	&::before {
		display: none;
	}

	&::after {
		width: 2.8vw;
		height: 2.8vw;
		top: calc(50% - 1.4vw);
		right: .8vw;
		border-right: none;
		border-bottom: none;
		transform: rotate(0);
		background: url(../images/common/pdf_white.svg) no-repeat center center;
	}
}

a[href$=".docx"].link-btn {
	&::before {
		display: none;
	}

	&::after {
		width: 2.8vw;
		height: 2.8vw;
		top: calc(50% - 1.4vw);
		right: .8vw;
		border-right: none;
		border-bottom: none;
		transform: rotate(0);
		background: url(../images/common/word_white.svg) no-repeat center center;
	}
}

a[href$=".doc"].link-btn {
	&::before {
		display: none;
	}

	&::after {
		width: 2.8vw;
		height: 2.8vw;
		top: calc(50% - 1.4vw);
		right: .8vw;
		border-right: none;
		border-bottom: none;
		transform: rotate(0);
		background: url(../images/common/word_white.svg) no-repeat center center;
	}
}

a[href$=".xls"].link-btn {
	&::before {
		display: none;
	}

	&::after {
		width: 2.8vw;
		height: 2.8vw;
		top: calc(50% - 1.4vw);
		right: .8vw;
		border-right: none;
		border-bottom: none;
		transform: rotate(0);
		background: url(../images/common/excel_white.svg) no-repeat center center;
	}
}

a[href$=".xlsx"].link-btn {
	&::before {
		display: none;
	}

	&::after {
		width: 2.8vw;
		height: 2.8vw;
		top: calc(50% - 1.4vw);
		right: .8vw;
		border-right: none;
		border-bottom: none;
		transform: rotate(0);
		background: url(../images/common/excel_white.svg) no-repeat center center;
	}
}


// /* 項目リスト */

.box-list {
	margin: 0 auto 4vh;

	>li {
		// padding-left: 1em;
		margin-bottom: .6em;
		position: relative;
		line-height: 1.5em;
		text-indent: -2em;
		padding-left: 3em;
		font-size: 1.5rem;

		span.red {
			color: red;
			text-decoration: underline;
		}

		&::before {
			content: '';
			background: $main-color;
			margin-right: 0.8em;
			display: inline-block;
			width: .6em;
			height: .6em;
			transform: translateY(-.1em);
		}
	}

	&.list-flex {
		display: flex;
		flex-wrap: wrap;

		>li {
			margin-bottom: .6em;
			padding-left: 3em;
		}
	}
}

.num-list {
	margin: 0 auto 4vh;
	list-style: none;
	counter-reset: item;

	li {
		// padding-left: 1em;
		margin-bottom: .6em;
		position: relative;
		line-height: 1.5em;
		text-indent: -1em;
		padding-left: 3em;
		font-size: 1.5rem;

		&::before {
			counter-increment: item;
			content: counter(item)'.';
			color: $main-color;
			font-weight: 500;
			margin: 0 0.7em 0 0;
			display: inline-block;
		}
	}
}


.link-list {
	margin: 0 auto 4vh;
	list-style: none;


	li {
		// padding-left: 1em;
		margin-bottom: .3em;
		position: relative;
		line-height: 1.6em;
		padding-left: 1em;

		a {
			display: inline-block;
			color: $main-color;
			font-weight: 500;
			text-decoration: underline;
			font-size: 1.5rem;

			.large {
				font-size: 1.8rem;
				font-weight: 500;
				display: inline-block;
				text-decoration: underline;
			}

			&[target="_blank"] {
				&::after {
					content: '';
					width: 1.5em;
					height: 1.5em;
					border-radius: .3em;
					display: inline-block;
					margin-left: 1em;
					transform: translateY(.3em);
					background: rgba($main-color, 1) url(../images/common/arrow_out_white.svg) no-repeat center center;
				}

			}

			&[href$=".pdf"] {
				&::after {
					content: '';
					width: 1.5em;
					height: 1.5em;
					border-radius: .3em;
					display: inline-block;
					margin-left: 1em;
					transform: translateY(.3em);
					background: rgba($main-color, 1) url(../images/common/pdf_white.svg) no-repeat center center;
				}
			}

			&[href$=".docx"],
			&[href$=".doc"] {
				&::after {
					content: '';
					width: 1.5em;
					height: 1.5em;
					border-radius: .3em;
					display: inline-block;
					margin-left: 1em;
					transform: translateY(.3em);
					background: rgba($main-color, 1) url(../images/common/word_white.svg) no-repeat center center;
				}
			}

			&[href$=".xlsx"],
			&[href$=".xls"] {
				&::after {
					content: '';
					width: 1.5em;
					height: 1.5em;
					border-radius: .3em;
					display: inline-block;
					margin-left: 1em;
					transform: translateY(.3em);
					background: rgba($main-color, 1) url(../images/common/excel_white.svg) no-repeat center center;
				}
			}

			&.no-link {
				pointer-events: none;
			}
		}

		&::before {
			content: '';
			border-right: 1px solid $main-color;
			border-top: 1px solid $main-color;
			transform: rotate(45deg) translateY(-.3em);
			margin-right: 0.8em;
			display: inline-block;
			width: .4em;
			height: .4em;
		}
	}
}


.btn-link-list {
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 4vw;

	li {
		width: 32%;
		margin-right: 2%;
		margin-bottom: 1vw;

		a,
		p {
			width: 100%;
			min-height: 4vw;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.6rem;
			line-height: 1.5em;
			letter-spacing: 0.02em;
			color: $main-color;
			background: #FFF;
			position: relative;
			padding: .5em 2.5em .5em 1em;
			height: 100%;

			&::before {
				content: '';
				width: 1.2vw;
				height: 1.2vw;
				background: $main-color;
				position: absolute;
				top: calc(50% - .6vw);
				right: 1vw;
			}

			&::after {
				content: '';
				width: .4vw;
				height: .4vw;
				border-right: 1px solid #FFF;
				border-bottom: 1px solid #FFF;
				position: absolute;
				top: calc(50% - .2vw);
				right: 1.5vw;
				transform: rotate(-45deg);
			}
		}

		p {
			background: none;
			border: 1px solid $main-color;

			&::before,
			&::after {
				display: none;
			}
		}

		&:nth-of-type(3n) {
			margin-right: 0;
		}
	}
}

// /* テーブルレイアウト */

table.table-std {
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 5vh;
	border-top: 1px solid $main-color;
	border-bottom: 1px solid $main-color;
	border-left: 1px solid $main-color;

	&.harf {
		width: 50%;
	}

	thead {
		th {
			border-right: 1px solid #FFF;
			border-bottom: 1px solid #FFF;
			text-align: center;
			background: $main-color;
			color: #FFF;

			&:last-child {
				border-right: 1px solid $main-color;
			}
		}
	}

	th,
	td {
		padding: .8em .5em;
		font-size: 1.4rem;
		color: #000;

		border-bottom: 1px solid #CCC;
		border-right: 1px solid #CCC;
		border-bottom: 1px solid $main-color;
		border-right: 1px solid $main-color;

		text-align: left;
		vertical-align: middle;
		background: #FFF;

		&.center,
		.center {
			text-align: center;
		}

		&.left,
		.left {
			text-align: left;
		}

		&.right,
		.right {
			text-align: right;
		}
	}

	td {
		background: #FFF;

		&.subhead {
			background: rgba($main-color, .1);
			color: $main-color;
			font-weight: 500;
		}

		span.center {
			display: inline-block;
			width: 100%;
			text-align: center;
		}
	}

	a {
		display: inline-block;
		color: $main-color;
		text-decoration: underline;
	}
}

.table_res {
	display: block;
	width: 100%;
}


// /* テーブルレイアウト */

table.table-border {
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 5vh;

	&.harf {
		width: 50%;
	}

	thead {
		th {
			color: #000;

		}
	}

	th,
	td {
		padding: .8em .5em .2em;
		font-size: 1.4rem;
		color: #000;
		margin: 0 .5vw;

		border-bottom: 1px solid $main-color;

		text-align: left;
		vertical-align: middle;

		&.center,
		.center {
			text-align: center;
		}

		&.left,
		.left {
			text-align: left;
		}

		&.right,
		.right {
			text-align: right;
		}
	}

	td {
		&.subhead {
			background: rgba($main-color, .1);
			color: $main-color;
			font-weight: 500;
		}

		span.center {
			display: inline-block;
			width: 100%;
			text-align: center;
		}
	}

	a {
		display: inline-block;
		color: $main-color;
		text-decoration: underline;
	}
}


// /* column レイアウト */

.column-content {
	display: flex;
	justify-content: space-between;
	padding-bottom: 8vh;

	.text-column {
		width: 58%;
	}

	.topics-column {
		width: 38%;
		border: 2px solid $main-color;
		padding: 1.5vw 2.5vw;
	}

	.img-column {
		width: 34%;

		figure {
			img {
				width: 100%;
				display: block;
			}

			figcaption {
				font-size: 1.4rem;
				text-align: center;
				padding: .2em 0;
			}
		}
	}

	.half-column {
		width: 48%;
	}

	figure {
		img {
			width: 100%;
			display: block;
		}

		figcaption {
			font-size: 1.4rem;
			text-align: center;
			padding: .2em 0;
		}
	}
}


// /* 画像・MOVIE掲載 */

.media-content-wrap {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-bottom: 6vh;

	.media-content {
		width: 100%;

		&.large {
			width: 100%;
			padding: 1vw 10vw;
		}

		&.column {
			width: 46%;
			padding: 1vw 0 0;
		}

		&.full {
			width: 100%;
			padding: 1vw 0;
		}

		figure {
			img {
				width: 100%;
				display: block;
			}

			figcaption {
				font-size: 1.4rem;
				text-align: center;
				padding: .2em 0;
			}
		}

		iframe {
			width: 100%;
		}
	}
}

// /* タブコンテンツ */

.tab-content {
	margin-bottom: 6vh;

	.tab-btn {
		display: flex;
		margin-bottom: 3vh;

		.tab {
			cursor: pointer;
			background: #EFEFEF;
			color: #999999;
			text-align: center;
			font-size: 1.6rem;
			font-weight: 500;
			padding: .8em 0;
			border-bottom: 2px solid $main-color;

			&+.tab {
				border-left: 1px solid $main-color;
				border-left: none;
			}

			&.active {
				background: $main-color;
				background: linear-gradient(90deg, #182689 0, #2054bf 100%);
				color: #FFF;

				// border: 1px solid $main-color;
				&+.tab {
					border-left: none;
				}
			}
		}

		&.btn-two {
			.tab {
				width: 50%;
			}
		}

		&.btn-three {
			.tab {
				width: 33.333%;
			}
		}

		&.btn-four {
			.tab {
				width: 25%;
			}
		}
	}

	.panel-content {
		.panel-inner {
			padding-top: 2vh;
			display: none;

			&.active {
				display: block;
				animation: tabAnim ease 1s forwards;
				-ms-animation: tabAnim ease 1s forwards;
			}
		}
	}
}

@keyframes tabAnim {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}


.topics-content {
	background: $main-color;
	padding: 8vh 4vw 3vh;
	color: #FFF;
	border-radius: .5vw;
	margin: 4vw 0;
	box-shadow: 4px 4px 8px rgba($main-color, .4);

	h4.section-title {
		color: #FFF;

		&::before {
			background: #FFF;
		}
	}

	h4.border-title {
		color: #FFF;
		border: 1px solid #FFF;
	}

	h5.bold-title {
		color: #FFF;
	}

	h5.box-title {
		color: #FFF;

		&::before {
			background: #FFF;
		}
	}

	a.link-btn {
		color: #FFF;
		background: rgba(#FFF, .45);
	}

	&.light-color {
		background: $main-gray;
		padding: 8vh 4vw 3vh;
		color: #000;
		border-radius: .5vw;
		margin: 4vw 0;
		box-shadow: 4px 4px 8px rgba($main-gray, .4);

		h4.section-title {
			color: #000;

			&::before {
				background: $main-color;
			}
		}

		h4.border-title {
			color: #000;
			border: 1px solid #000;
		}

		h5.bold-title {
			color: #000;
		}

		h5.box-title {
			color: #000;

			&::before {
				background: $main-color;
			}
		}

		a.link-btn {
			color: #FFF;
			background: linear-gradient(90deg, #182689 0, #5085c0 70%, #2054bf 100%);
		}
	}
}




@import "module-res";